import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from 'layouts/zh'
import SEO from 'components/seo'
import SectionContent from 'components/SectionContent'

// import classnames from 'classnames'
import * as styles from './index.module.less'

const AboutCjo: React.FC = () => {
  return (
    <Layout>
      <AboutCjoState />
    </Layout>
  )
}

const AboutCjoState: React.FC = () => {
  const { aboutCjoYaml } = useStaticQuery(graphql`
    query {
      aboutCjoYaml {
        title
        desc
        keywords
        section1 {
          title
          dataList
        }
        section2 {
          title
          dataList
        }
        section3 {
          title
          dataList
          img {
            publicURL
          }
          imgDesc
        }
        section4 {
          title
          dataList
          img {
            publicURL
          }
        }
        section5 {
          title
          dataList
          img {
            publicURL
          }
          imgDesc
        }
      }
    }
  `)
  const { title, desc, keywords, section1, section2, section3, section4, section5 } = aboutCjoYaml
  return (
    <>
      <SEO title={title} description={desc} keywords={keywords} />
      <main className="lg:mt-[70px] mt-[4rem]">
        <SectionContent title={section1?.title}>
          <div className={styles.Content}>
            {section1?.dataList?.map((item) => {
              return <p key={item}>{item}</p>
            })}
          </div>
        </SectionContent>
        <SectionContent title={section2?.title}>
          <div className={styles.Content}>
            {section2?.dataList?.map((item) => {
              return <p key={item}>{item}</p>
            })}
          </div>
        </SectionContent>
        <SectionContent title={section3?.title}>
          <div className={styles.Content}>
            {section3?.dataList?.map((item) => {
              if (item === 'Placeholder character') {
                return (
                  <div className={styles.img}>
                    <img src={section3?.img?.publicURL ?? section3.img}></img>
                    {section3.imgDesc && <div className={styles.imgDesc}>{section3.imgDesc}</div>}
                  </div>
                )
              }
              return <p key={item}>{item}</p>
            })}
          </div>
        </SectionContent>
        <SectionContent title={section4?.title}>
          <div className={styles.Content}>
            {section4?.dataList?.map((item) => {
              if (item === 'Placeholder character') {
                return (
                  <div className={styles.img}>
                    <img src={section4?.img?.publicURL ?? section4.img}></img>
                    {section4.imgDesc && <div className={styles.imgDesc}>{section4.imgDesc}</div>}
                  </div>
                )
              }
              return <p key={item}>{item}</p>
            })}
          </div>
        </SectionContent>
        <SectionContent title={section5?.title}>
          <div className={styles.Content}>
            {section5?.dataList?.map((item) => {
              if (item === 'Placeholder character') {
                return (
                  <div className={styles.img}>
                    <img src={section5?.img?.publicURL ?? section5.img}></img>
                    {section5.imgDesc && <div className={styles.imgDesc}>{section5.imgDesc}</div>}
                  </div>
                )
              }
              return <p key={item}>{item}</p>
            })}
          </div>
        </SectionContent>
      </main>
    </>
  )
}

export default AboutCjo
